import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { reaction } from 'mobx';

import { appStore, Channel, RhinoEPGProgram, useFetchChannelPrograms } from '@kaltura-ott/tvpil-shared';

import { config } from 'components/pages/Epg/config';
import { epgLocalTimeStampStorage } from 'components/pages/Epg/storages';
import { WARNING_ICON } from 'consts';
import { useToastMessageContext } from 'context/toast/toast';
import { getWarningIcon } from 'utils';

import { filterVisiblePrograms } from '../../utils/filterVisiblePrograms/filterVisiblePrograms';

export function useVisiblePrograms(channel: Channel): RhinoEPGProgram[] {
  const intl = useIntl();
  const toastMessageContext = useToastMessageContext();
  const [visiblePrograms, setVisiblePrograms] = useState<any>([]);
  const warningIcon = `${appStore.staticAssetsUrl}${getWarningIcon(WARNING_ICON)}`;
  const { data: programs, error } = useFetchChannelPrograms({
    days: epgLocalTimeStampStorage.gridVisibleDays(),
    channelId: channel.id,
    withPlaceholders: {
      startDate: epgLocalTimeStampStorage.getStartDate(),
      endDate: epgLocalTimeStampStorage.getEndDate(),
    },
  });

  const setArray = useCallback(() => {
    const filteredPrograms = filterVisiblePrograms(programs);
    setVisiblePrograms(filteredPrograms);
  }, [programs]);

  useEffect(() => {
    const disposer = reaction(
      () => epgLocalTimeStampStorage.viewTimestamp,
      () => setArray(),
      {
        delay: config.delayForHorisontalFiltering,
      },
    );

    return () => {
      disposer();
    };
  }, [programs]);

  useEffect(() => {
    setArray();
  }, [programs]);

  useEffect(() => {
    if (error) {
      toastMessageContext.showToast({
        title: intl.formatMessage({
          id: `AppErrorReaction.mediumError`,
          defaultMessage: 'Sorry, this action cannot be completed.',
        }),
        icon: warningIcon,
      });
    }
  }, [error]);

  return visiblePrograms;
}
