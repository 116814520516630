import { useCallback, useEffect, useRef } from 'react';
import { add, getHours, getMilliseconds, getMinutes, getSeconds, startOfDay, subDays, subMinutes } from 'date-fns';

import { MAX_TIME_FOR_SWITCHING_DAY } from 'components/pages/Epg/constans';
import { DayProps } from 'components/pages/Epg/interface';
import { epgLocalTimeStampStorage } from 'components/pages/Epg/storages';
import { HALF_DIVIDER } from 'consts';

export function useSelectDay() {
  const timeRef = useRef({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

  useEffect(() => {
    timeRef.current = {
      hours: getHours(epgLocalTimeStampStorage.viewTimestamp),
      minutes: getMinutes(epgLocalTimeStampStorage.viewTimestamp),
      seconds: getSeconds(epgLocalTimeStampStorage.viewTimestamp),
      milliseconds: getMilliseconds(epgLocalTimeStampStorage.viewTimestamp),
    };
  }, [epgLocalTimeStampStorage.viewTimestamp]);

  return useCallback(
    (props: DayProps) => {
      const { day, delta } = props;
      let dayViewTime = day;

      if ([0, 0.5].includes(delta)) {
        dayViewTime = subMinutes(day, epgLocalTimeStampStorage.screenWidthInMinutes / HALF_DIVIDER);
      } else {
        let resolveDay = day;

        if (timeRef.current.hours >= MAX_TIME_FOR_SWITCHING_DAY) {
          resolveDay = subDays(day, 1);
        }

        dayViewTime = add(startOfDay(resolveDay), { ...timeRef.current });
      }

      epgLocalTimeStampStorage.setViewTimestamp(dayViewTime.getTime());
    },
    [epgLocalTimeStampStorage.viewTimestamp],
  );
}
