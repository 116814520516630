import React from 'react';

import Img from 'components/presenters/Img/Img';

import RailSkeleton from '../components/RailSkeleton/RailSkeleton';

import { useLogoRail } from './hooks/useLogoRail/useLogoRail';

import styles from './LogoRail.module.scss';

interface Props {
  referenceId: string;
}

function LogoRail({ referenceId }: Props) {
  const { imageUrl, isLoading, railSettings, error } = useLogoRail(referenceId);

  if ((!imageUrl && !isLoading) || error || !railSettings) {
    return null;
  }

  if (isLoading) {
    return <RailSkeleton className={styles.skeleton} settings={railSettings} numberOfCells={1} />;
  }

  return (
    <div className={styles.wrapper} style={{ width: railSettings.imageWidth, height: railSettings.imageHeight }}>
      <Img alt={railSettings.title} className={styles.logo} srcList={imageUrl} />
    </div>
  );
}

export default LogoRail;
