import { RefObject, useEffect } from 'react';
import { observer } from 'mobx-react';

import { DayProps } from 'components/pages/Epg/interface';

import { useIsActiveDay, useSelectDay } from './hooks';

export type WithCallback<T> = T & {
  callBack: (props: DayProps) => void;
};

interface Props {
  day: DayProps;
  forwardRef: RefObject<WithCallback<HTMLElement>>;
  primeTime: number;
}

function DayHooksCombiner({ day, forwardRef, primeTime }: Props) {
  const selectDay = useSelectDay();

  useIsActiveDay(day, forwardRef, primeTime);

  useEffect(() => {
    if (forwardRef.current) {
      forwardRef.current.callBack = selectDay;
    }
  }, []);

  return null;
}

export default observer(DayHooksCombiner);
