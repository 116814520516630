import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { reaction } from 'mobx';

import { BASE } from 'consts';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';

import { useAppStore } from '../common/useAppStore';
import { useDetectLocation } from '../common/useDetectLocation';
import { useLoginStore } from '../common/useLoginStore';
import { usePrefetchChannels } from '../common/usePrefetchChannels';

export function useAppPreloadAfterLoad() {
  const {
    appStore: { isReady },
  } = useAppStore();
  const location = useLocation();
  const [isNotAllowedCall, setIsNotAllowedCall] = useState(false);
  const { isAnonymous, isPostLoginProcessing } = useLoginStore();
  const [isPlayerLoaded, setIsPlayerLoaded] = useState<boolean | undefined>(undefined);
  const { isUrlToFullPlayer } = useDetectLocation();
  const checkIsPlayerComponentPossible = useCallback(() => {
    return !!document.getElementById(BASE) || isUrlToFullPlayer;
  }, [isUrlToFullPlayer]);
  const isLoginPage = location.pathname.includes(routes.login.path);
  const preloadPrefetchChannels = usePrefetchChannels(setIsNotAllowedCall);
  const disposer = reaction(
    () => globalStorage.isPossiblePresentPlayer,
    (isPossiblePresentPlayer: boolean | undefined) => {
      setIsPlayerLoaded(isPossiblePresentPlayer);
    },
  );

  useEffect(() => {
    if (isLoginPage || isAnonymous || isNotAllowedCall || !isReady || isPostLoginProcessing) return;
    if (checkIsPlayerComponentPossible()) {
      if (isPlayerLoaded !== undefined) {
        preloadPrefetchChannels();
      }
    } else {
      preloadPrefetchChannels();
    }

    return () => {
      disposer();
    };
  }, [
    isReady,
    isAnonymous,
    isLoginPage,
    isNotAllowedCall,
    isPlayerLoaded,
    checkIsPlayerComponentPossible,
    usePrefetchChannels,
    isPostLoginProcessing,
  ]);
}
