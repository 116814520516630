import { Dispatch, SetStateAction, useCallback } from 'react';

import { prefetchChannels } from '@kaltura-ott/tvpil-shared';

import { useAppStore } from 'hooks/common/useAppStore';

export const usePrefetchChannels = (setIsNotAllowedCall: Dispatch<SetStateAction<boolean>>) => {
  const { appStore } = useAppStore();

  return useCallback(() => {
    const epgReferenceId = appStore.rootStore.getEpgReferenceId();
    const heroReferenceId = appStore.rootStore.getHomePageHeroRailReferenceId();

    if (heroReferenceId) {
      prefetchChannels({ referenceId: heroReferenceId });
    }

    if (epgReferenceId) {
      prefetchChannels({ referenceId: epgReferenceId });
    }

    setIsNotAllowedCall(true);
  }, []);
};
