// @ts-nocheck
export const railObserver = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const rail = entry.target;
    
      if (rail && rail.callback) {
        rail.callback();
      }

      observer.unobserve(entry.target);
    }
  });
});
