import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { CollectionAsset, useGridSettings, useRecommendations } from '@kaltura-ott/tvpil-shared';

import { COMPONENT_NAME, PAGE_SIZE } from 'components/pages/Grids/constants';
import { useGridMatchPath } from 'components/pages/Grids/hooks/useGridMatchPath/useGridMatchPath';
import { useGridResetAdultPin } from 'components/pages/Grids/hooks/useGridResetAdultPin/useGridResetAdultPin';
import { WidgetProviderData } from 'context/widgetProvider/types';
import routes from 'routesPaths';
import { applyStyleHexToHSL, applyStyles } from 'utils';

function useRecommendationGrid() {
  const { type, id } = useGridMatchPath(routes.recommendationGrid.path);
  const [, setForceUpdate] = useState(false);

  const settings = useGridSettings({ gridReferenceId: type, railReferenceId: id });

  const { items, totalCount, isLoading, hasNextPage, fetchNextPage, error, refetch } = useRecommendations({
    referenceId: id,
    pageSize: PAGE_SIZE,
  });

  const data: WidgetProviderData<CollectionAsset> = {
    items,
    totalCount,
    hasNextPage,
    isLoading,
    error,
    fetchNextPage,
    refetch,
  };

  const wrapperRef = useRef(null);

  const intl = useIntl();

  const notValidPageTitle = intl.formatMessage({
    id: `${COMPONENT_NAME}.notValidPage`,
    defaultMessage: 'Not a valid page',
  });

  useEffect(() => {
    function initGridPage() {
      if (!settings) {
        return;
      }

      const node = wrapperRef?.current;

      if (node && settings.branding) {
        applyStyles(node, settings.branding, false, true);
        applyStyleHexToHSL(node, settings.branding.backgroundColor);
      }
    }

    initGridPage();
  }, [type, id, settings]);

  useGridResetAdultPin(settings);

  return {
    data,
    settings,
    wrapperRef,
    setForceUpdate,
    notValidPageTitle,
  };
}

export { useRecommendationGrid };
