import { action, observable } from 'mobx';

class PlayerEventsStorage {
  @observable isFirstPlay = false;

  @observable isFirstPlayEventFired = false;

  @observable isLoadMeta = false;

  @observable isPlayerDataLoaded = false;

  @observable isPlayerErrorEventFired = false;

  @observable isPlayerPlaybackEndedEventFired = false;

  @observable isSeamlessPrefetchEventFired = false;

  @observable isSeamlessTransitionEventFired = false;

  @action
  setIsSeamlessPrefetchEventFired(value: boolean) {
    this.isSeamlessPrefetchEventFired = value;
  }

  @action
  clearIsSeamlessPrefetchEventFired() {
    this.isSeamlessPrefetchEventFired = false;
  }

  @action
  setIsSeamlessTransitionEventFired(value: boolean) {
    this.isSeamlessTransitionEventFired = value;
  }

  @action
  clearIsSeamlessTransitionEventFired() {
    this.isSeamlessTransitionEventFired = false;
  }

  @action
  setIsPlayerPlaybackEnded(value: boolean) {
    this.isPlayerPlaybackEndedEventFired = value;
  }

  @action
  getIsPlayerPlaybackEnded() {
    return this.isPlayerPlaybackEndedEventFired;
  }

  @action
  clearIsPlayerPlaybackEnded() {
    this.isPlayerPlaybackEndedEventFired = false;
  }

  @action
  setIsFirstPlay(value: boolean) {
    this.isFirstPlay = value;
  }

  @action
  getIsFirstPlay() {
    return this.isFirstPlay;
  }

  @action
  clearIsFirstPlay() {
    this.isFirstPlay = false;
  }

  @action
  setIsFirstPlayEventFired(value: boolean) {
    this.isFirstPlayEventFired = value;
  }

  @action
  getIsFirstPlayEventFired() {
    return this.isFirstPlayEventFired;
  }

  @action
  clearIsFirstPlayEventFired() {
    this.isFirstPlayEventFired = false;
  }

  @action
  setIsLoadMeta(value: boolean) {
    this.isLoadMeta = value;
  }

  @action
  getIsLoadMeta() {
    return this.isLoadMeta;
  }

  @action
  clearIsLoadMeta() {
    this.isLoadMeta = false;
  }

  @action
  setIsPlayerDataLoaded(value: boolean) {
    this.isPlayerDataLoaded = value;
  }

  @action
  getIsPlayerDataLoaded() {
    return this.isPlayerDataLoaded;
  }

  @action
  clearIsPlayerDataLoaded() {
    this.isPlayerDataLoaded = false;
  }

  @action
  setIsPlayerErrorEventFired(value: boolean) {
    this.isPlayerErrorEventFired = value;
  }

  @action
  getIsPlayerErrorEventFired() {
    return this.isPlayerErrorEventFired;
  }

  @action
  clearIsPlayerErrorEventFired() {
    this.isPlayerErrorEventFired = false;
  }

  @action
  clearPlayerEventsStorage() {
    this.clearIsFirstPlayEventFired();
    this.clearIsFirstPlay();
    this.clearIsLoadMeta();
    this.clearIsPlayerDataLoaded();
    this.clearIsPlayerPlaybackEnded();
    this.clearIsSeamlessPrefetchEventFired();
    this.clearIsSeamlessTransitionEventFired();
  }
}

const playerEventsStorage = new PlayerEventsStorage();

export { playerEventsStorage };
