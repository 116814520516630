import React from 'react';
import { WidgetSettings } from 'types';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import { DynamicGridViewer } from 'components/pages/Grids/DynamicGridViewer/DynamicGridViewer';
import { useGridMatchPath } from 'components/pages/Grids/hooks/useGridMatchPath/useGridMatchPath';
import CollectionCardContainer from 'components/widgets/CardView/Collection/CollectionCardContainer/CollectionCardContainer';
import { WidgetProviderData } from 'context/widgetProvider/types';
import WidgetProvider from 'context/widgetProvider/widgetProvider';
import routes from 'routesPaths';

interface Props {
  type: string;
  settings: WidgetSettings;
  data: WidgetProviderData<CollectionAsset>;
}

function RecommendationGridContainer({ type, settings, data }: Props) {
  const { id } = useGridMatchPath(routes.recommendationGrid.path);

  return (
    <WidgetProvider<CollectionAsset> type={type} settings={settings} data={data}>
      <DynamicGridViewer<CollectionAsset> gridId={id}>
        {({ imageHeight, itemWidth, data: recommendations }) => (
          <CollectionCardContainer imageHeight={imageHeight} itemWidth={itemWidth} data={recommendations} />
        )}
      </DynamicGridViewer>
    </WidgetProvider>
  );
}

export { RecommendationGridContainer };
