import { millisecondsToSeconds } from 'date-fns';

import { appStore, createPlayerProgramConfigSources, isLiveProgram } from '@kaltura-ott/tvpil-shared';

import routes from 'routesPaths';
import { playoutStoreDataResolver } from 'utils';

import { getContentOptionsYoubora } from '../../services/getContentOptionsYoubora/getContentOptionsYoubora';
import { playerStorage, startOverInfoStorage } from '../../storages';
import { seamlessTransitionCleanup } from '../../utils/seamlessTransitionCleanup/seamlessTransitionCleanup';
import { handleRouteUpdates } from '../handleRouteUpdates/handleRouteUpdates';

export async function handleSeamlessTransition() {
  if (!playerStorage.seamlessMediaEntryData) {
    return;
  }

  const { player } = playerStorage.getPlayer();
  const { config } = player;
  const {
    seamlessMediaEntryData: { program, playbackContext },
  } = playerStorage;
  const { startDate, endDate } = playoutStoreDataResolver();
  const duration = millisecondsToSeconds(endDate - startDate);
  const isNextProgramLive = isLiveProgram(program);
  const newSources = createPlayerProgramConfigSources({ program, playbackContext });
  const startTime = isNextProgramLive ? 1 : 0;
  const { options } = getContentOptionsYoubora(program);
  const referrer = isNextProgramLive
    ? `${window.location.origin}${routes.startOver.link(program.id, program.type)}`
    : `${window.location.origin}${routes.catchUp.link(program.id, program.type)}`;

  const nextMediaEntry = {
    ...config,
    sources: {
      ...config.sources,
      ...newSources,
      id: program.id,
      startTime,
    },
    plugins: {
      ...config.plugins,
      ottAnalytics: {
        ...config.plugins.ottAnalytics,
        entryId: program.id,
      },
      youbora: {
        ...config.plugins.youbora,
        entryId: program.id,
        entryName: program.title,
        options,
      },
      kava: {
        ...config.plugins.kava,
        entryId: program.id,
        referrer,
      },
    },
  };

  if (isNextProgramLive) {
    startOverInfoStorage.setStartOver(true);
  }

  player.setMedia(nextMediaEntry);

  // to update meta in player description
  await appStore?.playoutStoreV2?.setCurrentTime(duration, duration);

  seamlessTransitionCleanup();

  // only when after seamless transition done and player id equals next asset.id
  if (player?.sources?.id === appStore.playoutStoreV2?.currentAsset?.id) {
    handleRouteUpdates();
  }
}
