import { useRef } from 'react';
import { useContainerPosition, useInfiniteLoader, usePositioner, useResizeObserver } from 'masonic';

import { useGridCardSizeChecker } from 'components/pages/Grids/hooks/useGridCardSizeChecker/useGridCardSizeChecker';
import { useGridCardsViewItems } from 'components/pages/Grids/hooks/useGridCardsViewItems/useGridCardsViewItems';
import { checkTotalGridHeight } from 'components/pages/Grids/utils/checkTotalGridHeight';
import { useWidgetContext } from 'context/widgetProvider/widgetProvider';

import { useWidthForMasonryWrapper } from '../useWidthForMasonryWrapper/useWidthForMasonryWrapper';

function useDynamicGridViewer(id: string) {
  const {
    imageHeight,
    imageWidth,
    data: { items, isLoading, hasNextPage, fetchNextPage },
  } = useWidgetContext();
  const stopIndexRef = useRef(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { columnCount, windowWidth, windowHeight, columnWidth, defaultHeight } = useGridCardSizeChecker();
  const { offset, width } = useContainerPosition(containerRef, [windowWidth, windowHeight]);
  const widthForMasonryWrapper = useWidthForMasonryWrapper({ width, columnCount, columnWidth });
  const isFullLoaded = !!items.length && !hasNextPage;
  const positioner = usePositioner(
    {
      width: widthForMasonryWrapper,
      columnWidth,
    },
    [id, items.length, isFullLoaded],
  );

  checkTotalGridHeight(containerRef, isFullLoaded, items.length, columnCount, defaultHeight);

  const resizeObserver = useResizeObserver(positioner);
  // masonic does not allow to remove items
  // to not count skeleton items height recalculate grid height if all items have been loaded
  const nextPageHandle = useInfiniteLoader(async (preIndex, stopIndex) => {
    if (hasNextPage && !isLoading && stopIndex !== stopIndexRef.current) {
      stopIndexRef.current = stopIndex;
      if (fetchNextPage) {
        fetchNextPage();
      }
    }
  });
  const { gridItems } = useGridCardsViewItems({ items, columnCount, isFullLoaded });
  const totalGridHeight = Math.ceil(items.length / columnCount) * defaultHeight;

  return {
    isFullLoaded,
    containerRef,
    totalGridHeight,
    windowHeight,
    columnWidth,
    defaultHeight,
    offset,
    gridItems,
    positioner,
    nextPageHandle,
    resizeObserver,
    itemWidth: imageWidth,
    imageHeight,
  };
}

export { useDynamicGridViewer };
