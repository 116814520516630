import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { playoutStoreDataResolver } from 'utils';

import { playerServiceManagement } from '../../services/PlayerServiceManagement';
import { playerEventsStorage, playerStorage, startOverInfoStorage } from '../../storages';
import { getBookmarkPosition } from '../../utils/getBookmarkPosition/getBookmarkPosition';
import { seamlessTransitionCleanup } from '../../utils/seamlessTransitionCleanup/seamlessTransitionCleanup';

export async function handleLoadChosenStream() {
  // to avoid calling loadmedia when calling seamless transition after tvpil updated playoutStoreV2.currentProgram via setCurrentTime(duration,duration)
  if (playerEventsStorage.isSeamlessTransitionEventFired) {
    return;
  }

  const playerService = playerServiceManagement.getPlayerService();
  const savedCurrentTime = playerStorage.getSavedCurrentTime();
  const { currentAsset, id, type, record, loadingNextProgram } = playoutStoreDataResolver();
  const { isStartOver, startTime: startOverStartTime } = startOverInfoStorage.getStartOver();
  let startPlayerTime = getBookmarkPosition();

  if (type === PlayoutStoreV2Type.LIVE) {
    startPlayerTime = -1;
  } else if (type === PlayoutStoreV2Type.START_OVER && startOverStartTime) {
    startPlayerTime = startOverStartTime;
  } else if (loadingNextProgram || isStartOver) {
    startPlayerTime = 0;
  } else if (savedCurrentTime) {
    // set start time after connection to internet
    startPlayerTime = savedCurrentTime;
  }

  // to clean up for not seamless transition flow
  seamlessTransitionCleanup();

  if (type === PlayoutStoreV2Type.START_OVER) {
    await playerService.startOver(id!, startPlayerTime || 1);
    startOverInfoStorage.setStartOver(true);
  } else if (type === PlayoutStoreV2Type.CATCHUP) {
    await playerService.catchUp(id!, startPlayerTime);
  } else if (type === PlayoutStoreV2Type.NPVR) {
    await playerService.records(record?.data?.id!, startPlayerTime);
  } else {
    await playerService.loadMedia(currentAsset?.id!, startPlayerTime);
  }
}
