import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import MainLayout from 'components/presenters/layouts/main/MainLayout';
import NoContent from 'components/widgets/NoContent/NoContent';
import routes from 'routesPaths';

import GridUpdatingHook from '../components/GridUpdatingHook/GridUpdatingHook';
import { COMPONENT_NAME } from '../constants';

import { RecommendationGridContainer } from './components/RecommendationGridContainer/RecommendationGridContainer';
import { useRecommendationGrid } from './hooks/useRecommendationGrid/useRecommendationGrid';

import styles from './RecommendationGrid.module.scss';

function RecommendationGrid() {
  const type = UIDAPresentationTypeEnum.collection;

  const { settings, data, wrapperRef, notValidPageTitle, setForceUpdate } = useRecommendationGrid();

  const shouldShowNoContent = !settings || !data;

  if (shouldShowNoContent) {
    return (
      <MainLayout>
        <NoContent title={notValidPageTitle} />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <GridUpdatingHook setForceUpdate={setForceUpdate} path={routes.recommendationGrid.path} />

      <div ref={wrapperRef} className={styles.wrapper}>
        <FormattedMessage
          defaultMessage={settings.title}
          id={`${COMPONENT_NAME}.title.${settings.title}`}
          tagName='h3'
        />

        <RecommendationGridContainer data={data} type={type} settings={settings} />
      </div>
    </MainLayout>
  );
}

export default React.memo(RecommendationGrid);
