import {
  AdapterCodec,
  AdapterQuality,
  appStore,
  fetchNextProgramPlaybackData,
  ProgramPlaybackData,
} from '@kaltura-ott/tvpil-shared';

import { checkIsMosaicProgram, isSafari, playoutStoreDataResolver } from 'utils';

import { ADAPTER_DATA, StreamFormat } from '../../services/consts';
import { playerStorage } from '../../storages';
import { clearSeamlessPrefetchEventWithDelay } from '../../utils/clearSeamlessPrefetchEventWithDelay/clearSeamlessPrefetchEventWithDelay';
import { getDrmSupport } from '../../utils/getDrmSupport/getDrmSupport';

export async function handleSeamlessPrefetch() {
  const { linearId, endDate, mosaicInfo } = playoutStoreDataResolver();
  const isMosaic = checkIsMosaicProgram(mosaicInfo);
  const channelId = isMosaic ? appStore.playoutStoreV2.getCurrentPermanentLinearAssetId() : linearId;

  if (!channelId) {
    clearSeamlessPrefetchEventWithDelay();
    return;
  }

  const isSafariBrowser = isSafari();
  const isDrmCapable = await getDrmSupport();
  const streamerType = isSafariBrowser ? StreamFormat.HLS : StreamFormat.DASH;
  let nextPlaybackData: ProgramPlaybackData | undefined;

  try {
    nextPlaybackData = await fetchNextProgramPlaybackData({
      channelId,
      endDate,
      playbackContext: {
        adapterCodec: ADAPTER_DATA.CODEC.AVC as AdapterCodec,
        adapterDrm: isDrmCapable,
        adapterQuality: ADAPTER_DATA.QUALITY.UHD as AdapterQuality,
        streamerType,
      },
    });
  } catch (error) {
    // to avoid repeated calls immediately after cleaning
    clearSeamlessPrefetchEventWithDelay();
  }

  if (!nextPlaybackData) {
    playerStorage.clearSeamlessMediaEntryData();
    return;
  }

  // save nextMediaEntryData
  playerStorage.setSeamlessMediaEntryData(nextPlaybackData);
}
