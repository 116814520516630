import { appStore } from '@kaltura-ott/tvpil-shared';

import { DRM_SUPPORTED } from '../../services/consts';
import { playerStorage } from '../../storages';

export async function getDrmSupport() {
  const { player } = playerStorage.getPlayer();
  const isDrmSupportedLocal = sessionStorage.getItem(DRM_SUPPORTED);
  const { isDrmDeny, drmCapable } = appStore?.rootStore?.playerStore || {};
  const isDrmAlowed = drmCapable && !isDrmDeny;

  if (!isDrmAlowed) return false;

  if (isDrmSupportedLocal) {
    return Boolean(isDrmSupportedLocal === '1');
  }

  const { isDRMSupported } = await player.getMediaCapabilities(); // getMediaCapabilities returnes isDRMSupported = 1 or 0
  sessionStorage.setItem(DRM_SUPPORTED, isDRMSupported.toString());

  return Boolean(isDRMSupported);
}
