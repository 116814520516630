import React, { useMemo, useRef } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { QuotaStatusEnum, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { featureEnabler, PageStore } from '@kaltura-ott/tvpil-shared';

import Auth from 'components/pages/Auth/Auth';
import Base from 'components/pages/Base/Base';
import DeviceLimit from 'components/pages/DeviceLimit/DeviceLimit';
import Epg from 'components/pages/Epg/Epg';
import Grid from 'components/pages/Grid/Grid';
import CollectionGrid from 'components/pages/Grids/CollectionGrid/CollectionGrid';
import RecommendationGrid from 'components/pages/Grids/RecommendationGrid/RecommendationGrid';
import SeriesCatchUpGrid from 'components/pages/Grids/SeriesCatchUpGrid/SeriesCatchUpGrid';
import Records from 'components/pages/Records/Records';
import SearchPage from 'components/pages/SearchPage/SearchPage';
import PackageDetails from 'components/pages/Settings/PackageDetails/PackageDetails';
import Profile from 'components/pages/Settings/Settings';
import SettingsComponentResolver from 'components/pages/Settings/SettingsComponentResolver/SettingsComponentResolver';
import LiveVideoPage from 'components/pages/Video/LiveVideoPage';
import VodVideoPage from 'components/pages/Video/VodVideoPage';
import VideoDetails from 'components/pages/VideoDetails/VideoDetails/VideoDetails';
import ForgotPassword from 'components/widgets/Login/ForgotPassword/ForgotPassword';
import Login from 'components/widgets/Login/Login';
import { FEATURES } from 'consts';
import { useDetectLocation, useRecordRepository, useRootStore, useSettingsStore } from 'hooks';
import ModalRouter from 'route/ModalRouter/ModalRouter';
import PrivateRoute from 'route/PrivateRoute';
import { globalStorage } from 'services/globalStorage';

import routes from './routesPaths';

function RoutesElements() {
  const location = useLocation();
  const { isModal } = useDetectLocation();
  const previousLocation = useRef<any>(null);
  const rootStore = useRootStore();
  const { settingsStore } = useSettingsStore();
  const recordRepository = useRecordRepository();

  const shouldShowFavorites = Boolean(rootStore?.isFavoriteChannelPersonalization);
  const shouldShowPersonalLineup = Boolean(rootStore?.isPersonalLineupEnabled);

  if (isModal) {
    previousLocation.current = location;
  }

  const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
  const isModalProgramInfo = !!(isModal && previousLocation.current && !globalStorage.isNotFound);
  const internalPages = rootStore?.internalPages;
  const isEnabledCollectionGrid = featureEnabler.variation(FEATURES.IS_NEW_COLLECTION_GRID, false);
  const isEnabledSeriesCatchUpGrid = featureEnabler.variation(FEATURES.IS_NEW_SERIES_COLLECTION, false);

  const isNewRecommendationGridEnabled = featureEnabler.variation('ENABLE_NEW_RECOMMENDATION_GRID');

  const pageRoute = useMemo(() => {
    return internalPages?.map((child) => {
      const store = child as PageStore;
      let Component = <Base id={store.referenceId} />;

      if (store.subtype === UIDAPresentationTypeEnum.epg) {
        Component = <Epg referenceId={store.referenceId} />;
      } else if (store.subtype === UIDAPresentationTypeEnum.npvr) {
        if (!isNpvrEnabled) return null;
        Component = <Records store={store} />;
      }
      return [
        store.link,
        routes.page.link(store.referenceId),
        `${routes.page.link(store.referenceId)}${routes.detailsInfo.path}`,
        `${routes.page.link(store.referenceId)}${routes.detailsSeries.path}`,
      ].map((path) => <Route element={Component} key={store.referenceId} path={path} />);
    });
  }, [internalPages.length, isNpvrEnabled]);

  return (
    <>
      {isModalProgramInfo && (
        <ModalRouter>
          <Routes>
            <Route element={<PrivateRoute />}>
              {[
                `${routes.page.path}${routes.detailsInfo.path}`,
                `${routes.page.path}${routes.detailsSeries.path}`,
                `${routes.grid.path}${routes.detailsInfo.path}`,
                `${routes.grid.path}${routes.detailsSeries.path}`,
                `${routes.collectionGrid.path}${routes.detailsInfo.path}`,
                `${routes.collectionGrid.path}${routes.detailsSeries.path}`,
                `${routes.seriesCatchUpGrid.path}${routes.detailsInfo.path}`,
                `${routes.seriesCatchUpGrid.path}${routes.detailsSeries.path}`,
                `${routes.recommendationGrid.path}${routes.detailsInfo.path}`,
                `${routes.recommendationGrid.path}${routes.detailsSeries.path}`,
                `${routes.gridTheme.path}${routes.detailsInfo.path}`,
                `${routes.gridTheme.path}${routes.detailsSeries.path}`,
                routes.detailsSeries.path,
                routes.detailsInfo.path,
              ].map((path) => (
                <Route element={<VideoDetails />} key={path} path={path} />
              ))}
            </Route>
          </Routes>
        </ModalRouter>
      )}
      <Routes>
        <Route path={routes.home.path} element={<PrivateRoute />}>
          {pageRoute}
          {[
            `${routes.search.path}`,
            `${routes.search.path}${routes.detailsInfo.path}`,
            `${routes.search.path}${routes.detailsSeries.path}`,
          ].map((path) => (
            <Route element={<SearchPage />} key={path} path={path} />
          ))}
          {[routes.home.path, routes.home.alias, routes.detailsSeries.path, routes.detailsInfo.path].map((path) => (
            <Route element={<Base />} key={path} path={path} />
          ))}
          <Route element={<LiveVideoPage />} path={routes.live.path} />
          <Route element={<LiveVideoPage startOver />} path={routes.startOver.path} />
          {[
            `${routes.grid.path}`,
            `${routes.grid.path}${routes.detailsInfo.path}`,
            `${routes.grid.path}${routes.detailsSeries.path}`,
          ].map((path) => (
            <Route element={<Grid />} key={path} path={path} />
          ))}

          {[
            `${routes.collectionGrid.path}`,
            `${routes.collectionGrid.path}${routes.detailsInfo.path}`,
            `${routes.collectionGrid.path}${routes.detailsSeries.path}`,
          ].map((path) => isEnabledCollectionGrid && <Route element={<CollectionGrid />} key={path} path={path} />)}

          {[
            `${routes.seriesCatchUpGrid.path}`,
            `${routes.seriesCatchUpGrid.path}${routes.detailsInfo.path}`,
            `${routes.seriesCatchUpGrid.path}${routes.detailsSeries.path}`,
          ].map(
            (path) => isEnabledSeriesCatchUpGrid && <Route element={<SeriesCatchUpGrid />} key={path} path={path} />,
          )}

          {[
            `${routes.recommendationGrid.path}`,
            `${routes.recommendationGrid.path}${routes.detailsInfo.path}`,
            `${routes.recommendationGrid.path}${routes.detailsSeries.path}`,
          ].map(
            (path) =>
              isNewRecommendationGridEnabled && <Route element={<RecommendationGrid />} key={path} path={path} />,
          )}

          <Route element={<VodVideoPage />} path={routes.program.path} />
          <Route element={<VodVideoPage catchUp />} path={routes.catchUp.path} />
          <Route element={<VodVideoPage vod />} path={routes.vod.path} />
          <Route element={<DeviceLimit />} path={routes.household.path} />
          <Route element={<Profile />} path={routes.settings.path}>
            {settingsStore.children.map(({ link, subtype, referenceId }: any) => {
              const isFavorites = subtype === UIDAPresentationTypeEnum.favorites;
              // TODO PCL: change on real subtype
              const isPersonalLineup = subtype === UIDAPresentationTypeEnum.favorites;

              const isChannelPersonalizationCategory = isFavorites || isPersonalLineup;

              if (rootStore.isChannelPersonalizationDisabled && isChannelPersonalizationCategory) {
                return null;
              }

              const path = link.startsWith('/') ? link.substr(1) : link;

              return (
                <Route
                  element={
                    <SettingsComponentResolver
                      subtype={subtype}
                      referenceId={referenceId}
                      shouldShowFavorites={shouldShowFavorites}
                      shouldShowPersonalLineup={shouldShowPersonalLineup}
                    />
                  }
                  key={link}
                  path={path}
                />
              );
            })}
          </Route>
          <Route element={<PackageDetails />} path={`${routes.purchase.pathPackage}`} />
        </Route>
        <Route element={<Login />} path={routes.login.path} />
        <Route element={<Auth />} path={routes.auth.path} />
        <Route element={<ForgotPassword />} path={routes.forgotPassword.path} />
        <Route element={<Navigate to={routes.home.path} />} path={routes.notFound.path} />
        <Route element={<Navigate to={routes.home.path} />} path='*' />
      </Routes>
    </>
  );
}

export default RoutesElements;
