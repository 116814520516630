import { RefObject, useMemo } from 'react';
import { addMinutes, getHours, isSameDay } from 'date-fns';

import { MAX_HOURS } from 'components/pages/Epg/constans';
import { DayProps } from 'components/pages/Epg/interface';
import { epgLocalTimeStampStorage } from 'components/pages/Epg/storages';
import { HALF_DIVIDER } from 'consts';

export type WithCallback<T> = T & {
  callBack: (props: DayProps) => void;
};

export function useIsActiveDay(props: DayProps, forwardRef: RefObject<WithCallback<HTMLElement>>, primeTime: number) {
  const { day, delta } = props;
  const cursorTimeMiddleScreenMoment = addMinutes(
    epgLocalTimeStampStorage.viewTimestamp,
    epgLocalTimeStampStorage.screenWidthInMinutes / HALF_DIVIDER,
  );
  const viewHour = Math.ceil(getHours(cursorTimeMiddleScreenMoment));

  return useMemo(() => {
    const isPrimeTimeHour = viewHour >= primeTime && viewHour < MAX_HOURS;
    let isActive = false;

    if (isSameDay(cursorTimeMiddleScreenMoment, day)) {
      isActive = true;

      if ((delta === 0 && isPrimeTimeHour) || (delta === 0.5 && !isPrimeTimeHour)) {
        isActive = false;
      }
    }

    if (forwardRef.current) {
      forwardRef.current.setAttribute('active', `${isActive}`);
    }
  }, [epgLocalTimeStampStorage.viewTimestamp]);
}
