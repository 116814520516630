import { useRef } from 'react';

import {
  DEFAULT_MAX_EPG_DAYS_BACKWARD,
  DEFAULT_MAX_EPG_DAYS_FORWARD,
  EVENING_START_HOUR,
} from 'components/pages/Epg/constans';
import { useEpgContext } from 'context/epgContext/epgContext';

import { useMouseDownHandler, useMouseMoveHandler, useMouseUpHandler } from '../../handlers';
import { useGenerateDays } from '../useGenerateDays/useGenerateDays';
import { useScrollToCenter } from '../useScrollToCenter/useScrollToCenter';

function useDaysHooksCombiner() {
  const { settings } = useEpgContext();
  // epg days data
  const maxEpgDaysForward = settings?.maxEpgDaysForward ?? DEFAULT_MAX_EPG_DAYS_FORWARD;
  const maxEpgDaysBackward = settings?.maxEpgDaysBackward ?? DEFAULT_MAX_EPG_DAYS_BACKWARD;
  const primeTime = settings?.primeTime ?? EVENING_START_HOUR;
  const days = useGenerateDays({ primeTime, maxEpgDaysBackward, maxEpgDaysForward });
  // refs
  const blockRef = useRef<HTMLDivElement | null>(null);
  const drag = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  // handlers
  const onMouseDownHandler = useMouseDownHandler({ drag, blockRef, startX, scrollLeft });
  const onMouseMoveHandler = useMouseMoveHandler({ drag, blockRef, startX, scrollLeft });
  const onMouseUpHandler = useMouseUpHandler(drag);

  useScrollToCenter(blockRef);

  return {
    days,
    blockRef,
    onMouseDownHandler,
    onMouseMoveHandler,
    onMouseUpHandler,
    primeTime,
  } as any;
}

export { useDaysHooksCombiner };
