import { getAssetImageUrl, LogoSettings, useLogo, useLogoSettings } from '@kaltura-ott/tvpil-shared';

import { LANDSCAPE_RATIO, SCALING_IMAGE } from 'consts';

import { DEFAULT_LANDSCAPE_HEIGHT, DEFAULT_PORTRAIT_HEIGHT } from '../../consts';

function useLogoRail(referenceId: string) {
  const settings = useLogoSettings({
    referenceId,
    defaultImageRatio: LANDSCAPE_RATIO,
    defaultLandscapeImageHeight: DEFAULT_LANDSCAPE_HEIGHT,
    defaultPortraitImageHeight: DEFAULT_PORTRAIT_HEIGHT,
  });
  const { logo, isLoading, error } = useLogo({ referenceId });
  let imageUrl = '';

  if (!isLoading && settings?.imageHeight && settings.imageType && settings.imageWidth && logo?.images) {
    imageUrl =
      getAssetImageUrl(logo.images, {
        height: settings.imageHeight * SCALING_IMAGE,
        type: settings.imageType,
        width: settings.imageWidth * SCALING_IMAGE,
      }) || '';
  }

  const railSettings: LogoSettings = {
    imageHeight: settings?.imageHeight,
    imageType: settings?.imageType,
    imageWidth: settings?.imageWidth,
    imageRatio: settings?.imageRatio,
    title: settings?.title || '',
  };

  return { imageUrl, isLoading, railSettings, error };
}

export { useLogoRail };
