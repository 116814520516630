import { millisecondsToSeconds } from 'date-fns';

import { appStore, featureEnabler, Program } from '@kaltura-ott/tvpil-shared';
import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { bingeWatchingStorage, playerEventsStorage, playerStorage } from 'components/widgets/Player/storages';
import { FEATURES } from 'consts';
import { playoutStoreDataResolver } from 'utils';

import { seekStorage } from '../../components/SeekBar/storages/SeekStorage';
import { CUSTOM_PLAYER_EVENTS } from '../../constants';
import { dispatchEvent } from '../../utils/dispatchEvent/dispatchEvent';

interface Props {
  target: {
    normalizedCurrentTime: number;
    normalizedDuration: number;
    paused: boolean;
  };
}

// eslint-disable-next-line consistent-return
export async function handleUpdateCurrentTime({ target }: Props) {
  const pr = seekStorage.getSeekProps();
  const { currentTime: currentSeekbarTime } = pr;
  const { normalizedCurrentTime, normalizedDuration, paused } = target;
  const { transitionDistance, prefetchDistance } = featureEnabler.variation(
    FEATURES.PROGRAM_SEAMLESS_TRANSITION_POLICY,
    { prefetchDistance: 0 },
  );

  if (normalizedCurrentTime && normalizedDuration) {
    const { currentEntity, isVod, type, autoplayNextProgram } = playoutStoreDataResolver();
    const isSeamlessTransitionEnabled = Boolean(prefetchDistance > 0 && transitionDistance);
    const program = currentEntity as Program;
    const isNpvr = type === PlayoutStoreV2Type.NPVR;
    const isLive = type === PlayoutStoreV2Type.LIVE;
    const isCatchUp = type === PlayoutStoreV2Type.CATCHUP;
    const isStartOver = type === PlayoutStoreV2Type.START_OVER;
    const isPausedLive = isLive && playerStorage.isPlayBackPaused;
    const isOnLiveEdge = isLive && !playerStorage.isPlayBackPaused;
    const playoutStoreDuration = millisecondsToSeconds(program?.data?.endDate - program?.data?.startDate);
    const { isSeamlessTransitionEventFired, isSeamlessPrefetchEventFired } = playerEventsStorage;
    const { seamlessMediaEntryData } = playerStorage;

    let currentTime = normalizedCurrentTime;
    let duration =
      isVod || isNpvr || isStartOver || isPausedLive
        ? normalizedDuration || 0
        : millisecondsToSeconds((currentEntity as Program)?.data?.duration ?? 0);

    if (isCatchUp || isOnLiveEdge) {
      duration = playoutStoreDuration;
    }

    if (isCatchUp && playoutStoreDuration > normalizedDuration) {
      duration = normalizedDuration;
    }

    if (isOnLiveEdge) {
      currentTime = millisecondsToSeconds(Date.now() - (program?.data?.startDate || 0));
    }

    if (currentTime >= duration) {
      if (
        currentSeekbarTime &&
        isLive &&
        currentTime > Math.round(currentSeekbarTime) &&
        Date.now() > program?.data?.endDate
      ) {
        currentTime = Math.round(currentSeekbarTime);
      }

      dispatchEvent(CUSTOM_PLAYER_EVENTS.PLAYBACK_ENDED);
    }

    if (isSeamlessTransitionEnabled && autoplayNextProgram && (isCatchUp || isStartOver)) {
      const roundedCurrentTime = Math.floor(currentTime);
      const seamlessDuration = isStartOver ? playoutStoreDuration : duration;
      const shouldSeamlessPrefetch =
        !isSeamlessPrefetchEventFired && roundedCurrentTime === seamlessDuration - prefetchDistance;

      if (shouldSeamlessPrefetch) {
        dispatchEvent(CUSTOM_PLAYER_EVENTS.SEAMLESS_PREFETCH);
        playerEventsStorage.setIsSeamlessPrefetchEventFired(true);
      }

      const shouldSeamlessTransition =
        !isSeamlessTransitionEventFired &&
        !!seamlessMediaEntryData &&
        roundedCurrentTime === seamlessDuration - transitionDistance;

      if (shouldSeamlessTransition) {
        dispatchEvent(CUSTOM_PLAYER_EVENTS.SEAMLESS_TRANSITION);
        playerEventsStorage.setIsSeamlessTransitionEventFired(true);

        return;
      }
    }

    if (!paused || currentTime >= duration) {
      await appStore?.playoutStoreV2?.setCurrentTime(currentTime, duration, bingeWatchingStorage.settings);
    }
  }
}
