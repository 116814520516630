import { useEffect, useRef } from 'react';
import { addHours, isSameHour, minutesInHour } from 'date-fns';
import { observer } from 'mobx-react';

import { epgLocalTimeStampStorage } from 'components/pages/Epg/storages';

import { useHandleClick } from './hooks';

interface Props {
  forwardSetItems: (
    value: { time: number; callBack: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }[],
  ) => void;
}

const TimeLineHooks = ({ forwardSetItems }: Props) => {
  const prevTime = useRef(0);
  const handleClick = useHandleClick();

  useEffect(() => {
    const { viewTimestamp } = epgLocalTimeStampStorage;
    const isHourSame = isSameHour(viewTimestamp, prevTime.current);

    if (isHourSame) return;

    const { screenWidthInMinutes } = epgLocalTimeStampStorage;
    const items: { time: number; callBack: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }[] = [];

    for (let i = 0; i < screenWidthInMinutes / minutesInHour; i += 1) {
      const time = addHours(new Date(viewTimestamp), i).getTime();

      items.push({ time, callBack: handleClick });
    }

    forwardSetItems(items);

    prevTime.current = epgLocalTimeStampStorage.viewTimestamp;
  }, [epgLocalTimeStampStorage.viewTimestamp]);

  return null;
};

export default observer(TimeLineHooks);
